import React from "react"
import * as styles from "./lilschk.module.scss"
import { Link } from "gatsby"


export default function ViewModule(){

    return (
        <div className={styles.lilschkDiv}>
             <Link to="/"><div className={styles.l}> <span>l</span></div></Link>
             <Link to="/"><div className={styles.il}><span>il</span></div></Link>
             <Link to="/"><div className={styles.sch}><span>sch</span></div></Link>
             <Link to="/"><div className={styles.k}><span>k</span></div></Link>
        </div>
    )}



