import React from "react"
import * as styles from "./headline.module.scss"


export default function ViewModule({title}){

    return (
        <div>
            <h1 className={styles.headline}>{title}</h1>
        </div>
    )}
